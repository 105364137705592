import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import App from "./App";
import Cookies from "js-cookie";
import "@fontsource/roboto";
import "bootstrap/dist/css/bootstrap.min.css";
import { PageProvider } from "./PageContext";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request interceptor
instance.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get("AuthToken");
    const userEmail = Cookies.get("Email");

    if (authToken) {
      config.headers.Authorization = authToken;
    }

    if (userEmail) {
      config.headers["User-Email"] = userEmail;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized, clear cookies and redirect to login
      console.log("Session expired. Please login again.");
      Cookies.remove("Email");
      Cookies.remove("AuthToken");
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PageProvider>
      <App />
    </PageProvider>
  </React.StrictMode>
);
