import React, { useState, useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import Header from "./components/Header";
import BookSearch from "./components/BookSearch";
import NoteTakingPage from "./components/NoteTakingPage";
import About from "./components/About";
import Auth from "./components/Auth";
import PasswordReset from "./components/PasswordReset";
import { CssBaseline, Container } from "@mui/material";
import PageContext from "./PageContext";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["AuthToken", "Email"]);
  const authToken = cookies.AuthToken;

  const { selectedOption, setSelectedOption } = useContext(PageContext);

  const hasResetToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has("token") && urlParams.get("token").trim() !== "";
  };

  const resetToken = hasResetToken();

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <CssBaseline />
      {authToken ? (
        <div>
          <Header header={"LitLogs"} onButtonClick={handleButtonClick} />
          <main>
            <Container>
              {selectedOption === "Book Search" && <BookSearch />}
              {selectedOption === "Notes" && <NoteTakingPage />}
              {selectedOption === "About" && <About />}
            </Container>
          </main>
        </div>
      ) : resetToken ? (
        <PasswordReset />
      ) : (
        <Auth />
      )}
    </div>
  );
}

export default App;
