import React, { createContext, useState, useEffect } from "react";

const PageContext = createContext();

export const PageProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("selectedOption") || "Book Search"
  );

  useEffect(() => {
    localStorage.setItem("selectedOption", selectedOption);
  }, [selectedOption]);

  const value = {
    selectedOption,
    setSelectedOption,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export default PageContext;
