import React, { useEffect, useState, useContext } from "react";
import { getBookCover } from "../services/apiService";
import { Grid2, Box, Typography } from "@mui/material";
import {
  BookItem,
  Thumbnail,
  BookTitle,
  BookAuthor,
  LazyImageContainer,
} from "../styles";
import PageContext from "../PageContext";
import LazyImage from "./LazyImage";

const BookGrid = ({ books }) => {
  const [coverUrls, setCoverUrls] = useState([]);
  const { selectedOption, setSelectedOption } = useContext(PageContext);
  useEffect(() => {
    if (!books || books.length === 0) return;
    const fetchImages = async () => {
      const promises = books.map((book) => getBookCover(book.book_isbn));
      const urls = await Promise.all(promises);
      setCoverUrls(urls);
    };
    fetchImages().catch((error) =>
      console.error("Error fetching cover images:", error)
    );
  }, [books]);

  const handleTileClick = (bookISBN) => {
    const selectedBook = books.find((book) => book.book_isbn === bookISBN);
    localStorage.setItem("lastSelectedBook", JSON.stringify(selectedBook));
    setSelectedOption("Notes");
    //window.location.reload();
  };

  return (
    <Grid2 container spacing={4} justifyContent="center">
      {books && books.length > 0 ? (
        books.map((book, index) => (
          <Grid2 item xs={6} sm={6} md={3} key={book.book_isbn}>
            <BookItem onClick={() => handleTileClick(book.book_isbn)}>
              <LazyImageContainer>
                <LazyImage
                  src={coverUrls[index]}
                  hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj" // TO DO : use actual image hash when fading in
                  alt={book.title}
                  loading="lazy"
                  onError={() =>
                    console.error(`Error loading thumbnail for ${book.title}`)
                  }
                />
              </LazyImageContainer>
              <BookTitle variant="h6">{book.title}</BookTitle>
              <BookAuthor variant="body2">{book.author}</BookAuthor>{" "}
            </BookItem>
          </Grid2>
        ))
      ) : (
        <Grid2 item xs={12}>
          <Box textAlign="center">
            <Typography variant="body1">
              Your library is empty - search for books to add.
            </Typography>
          </Box>
        </Grid2>
      )}
    </Grid2>
  );
};

export default BookGrid;
