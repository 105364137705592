import instance from "../index";
import axios from "axios";
const baseURL =
  process.env.REACT_APP_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

export function getBooks(email) {
  // Create a unique key for storing the books data by user email
  const storageKey = `books_${email}`;

  // Check if books data exists in local storage
  const cachedBooks = localStorage.getItem(storageKey);
  if (cachedBooks) {
    // Parse and return the cached books data if found
    return Promise.resolve(JSON.parse(cachedBooks));
  }

  // If books data is not cached, fetch from the server
  return instance
    .post(`${baseURL}/book/get-books`, { email })
    .then((response) => {
      const books = response.data;

      // Save the retrieved books data to local storage
      localStorage.setItem(storageKey, JSON.stringify(books));

      return books;
    })
    .catch((error) => {
      console.error("getBooks error:", error);
      return Promise.reject(error);
    });
}

export function getNotes(bookIsbn, email) {
  return instance
    .post(`${baseURL}/notes/get-notes`, {
      email: email,
      book_isbn: bookIsbn,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("getNotes error:", error);
      return Promise.reject(error);
    });
}

export function saveNoteToDatabase(book_isbn, newNote, email) {
  const currentDate = new Date();

  return instance
    .post(`${baseURL}/notes/add-note`, {
      book_isbn: book_isbn,
      email: email,
      date: currentDate,
      notes: newNote,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("saveNoteToDatabase error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
      }
      return Promise.reject(error);
    });
}

export function getBookCover(isbn) {
  // Check if the image URL for this ISBN is already in localStorage
  const cachedImageUrl = localStorage.getItem(`cover_${isbn}`);

  if (cachedImageUrl) {
    // If found in localStorage, return it as a resolved promise
    return Promise.resolve(cachedImageUrl);
  }

  return axios
    .post(`${baseURL}/book/get-thumbnail`, { isbn })
    .then((response) => {
      const imageURL = response.data.imageUrl;
      // Save the fetched URL in localStorage for future use
      localStorage.setItem(`cover_${isbn}`, `${baseURL}/${imageURL}`);
      return `${baseURL}/${imageURL}`; // Return the image URL from the server
    })
    .catch((error) => {
      return "https://picsum.photos/128/192";
    });
}

export function getBookAuthor(isbn, email) {
  // Get books from local storage
  const storageKey = `books_${email}`;
  const cachedBooks = localStorage.getItem(storageKey);

  if (cachedBooks) {
    // Parse the cached books and try to find the book by ISBN
    const books = JSON.parse(cachedBooks);
    const book = books.find((b) => b.book_isbn === isbn);

    // If the book and author are found, return the author directly
    if (book && book.author) {
      return Promise.resolve(book.author);
    }
  }

  // Fallback to Google Books API if the author is not found in local storage
  const url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`;
  return axios
    .get(url)
    .then((response) => {
      const bookData = response.data.items[0];
      if (bookData && bookData.volumeInfo.authors) {
        return bookData.volumeInfo.authors[0];
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error fetching author from Google Books API:", error);
      return Promise.reject(error);
    });
}

export const updateNoteById = async (notes_id, updatedNote, email) => {
  try {
    const response = await instance.post(`${baseURL}/notes/update-note`, {
      notes_id,
      note_update: updatedNote,
      email,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNoteById = async (notes_id, email) => {
  try {
    const response = await instance.post(`${baseURL}/notes/delete-note`, {
      notes_id,
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeBook = async (isbn, email) => {
  try {
    const [bookResponse, notesResponse] = await Promise.all([
      instance.post(`${baseURL}/book/delete-book`, { isbn, email }),
      instance.post(`${baseURL}/notes/delete-all-book-notes`, { isbn, email }),
    ]);

    const isSuccess = [bookResponse, notesResponse].every(
      (response) => response.status === 200 || response.status === 404
    );

    return isSuccess;
  } catch (error) {
    const bookError = error.response?.config.url.includes("delete-book")
      ? { status: error.response.status, data: error.response.data }
      : null;

    const notesError = error.response?.config.url.includes(
      "delete-all-book-notes"
    )
      ? { status: error.response.status, data: error.response.data }
      : null;

    return {
      bookError,
      notesError,
      error: bookError || notesError || { message: error.message },
    };
  }
};

export function emailNotes(isbn, title, author, email, recipientEmail) {
  const postData = {
    isbn,
    title,
    author,
    email,
    recipientEmail,
  };

  return instance
    .post(`${baseURL}/notes/email-notes`, postData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error sending email notes:", error);
      return Promise.reject(error);
    });
}
