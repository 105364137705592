import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { getBooks } from "../services/apiService";

function BookSelection({ onBookSelect }) {
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const [books, setBooks] = useState([]);
  const cookie_email = cookies.Email;

  useEffect(() => {
    getBooks(cookie_email)
      .then((data) => {
        setBooks(data);
      })
      .catch((error) => console.error("Error fetching books:", error));
  }, []);

  const handleBookSelection = (e) => {
    const selectedBookIsbn = e.target.value;
    const selectedBook = books.find(
      (book) => book.book_isbn === selectedBookIsbn
    );
    onBookSelect(selectedBook);
  };

  return (
    <div className="my-2">
      {books.length > 0 ? (
        <>
          <label htmlFor="bookSelect" className="form-label"></label>
          <select
            className="form-select"
            id="bookSelect"
            onChange={handleBookSelection}
            style={{ width: "100%" }}
          >
            <option value="">Select a Book</option>
            {books.map((book) => (
              <option key={book.book_isbn} value={book.book_isbn}>
                {book.title}
              </option>
            ))}
          </select>
        </>
      ) : (
        <p className="text-center">Add books to your Library to log notes!</p>
      )}
    </div>
  );
}

export default BookSelection;
