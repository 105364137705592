import React from "react";
import { useCookies } from "react-cookie";
import {
  Typography,
  AppBar,
  CssBaseline,
  Container,
  Toolbar,
  Button,
  Stack,
  useMediaQuery,
  Box,
} from "@mui/material";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
function Header({ header, onButtonClick }) {
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const userEmail = cookies.Email;
  const userName = cookies.Name;

  function signOut() {
    const isConfirmed = window.confirm("Are you sure you want to Sign Out?");
    if (isConfirmed) {
      console.log("sign-out");
      removeCookie("Email");
      removeCookie("AuthToken");
      removeCookie("Name");
      localStorage.clear();
      localStorage.removeItem("selectedOption");
    }
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <AppBar position="relative">
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            variant="h6"
            component="div"
            sx={{
              color: "inherit",
              textTransform: "none",
              minWidth: "auto",
            }}
            onClick={() => onButtonClick("Book Search")}
          >
            <BookOutlinedIcon />
            LitLogs
          </Button>
        </Box>

        <Stack direction="row" spacing={1}>
          <Button
            size={isMobile ? "small" : "medium"}
            color="inherit"
            onClick={() => onButtonClick("Book Search")}
          >
            Library
          </Button>
          <Button
            size={isMobile ? "small" : "medium"}
            color="inherit"
            onClick={() => onButtonClick("Notes")}
          >
            Log
          </Button>
          <Button
            size={isMobile ? "small" : "medium"}
            color="inherit"
            onClick={() => onButtonClick("About")}
          >
            About
          </Button>
          <Button
            size={isMobile ? "small" : "medium"}
            color="inherit"
            onClick={signOut}
          >
            Sign Out
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
