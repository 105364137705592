import React, { useState } from "react";
import { Blurhash } from "react-blurhash";
import {
  LazyImageWrapper,
  LazyImageElement,
  BlurhashPlaceholder,
} from "../styles";

const LazyImage = ({
  src,
  hash,
  alt,
  className = "",
  style = {},
  loading = "lazy",
  onError,
  width = "100%",
  height = "auto",
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <LazyImageWrapper style={{ width, height, ...style }} className={className}>
      {/* BlurHash Placeholder */}
      {!isLoaded && hash && (
        <BlurhashPlaceholder>
          <Blurhash
            hash={hash}
            width="100%"
            height="100%"
            resolutionX={32}
            resolutionY={32}
            punch={0.5}
          />
        </BlurhashPlaceholder>
      )}

      {/* Actual Image with Smooth Transition */}
      <LazyImageElement
        src={src}
        alt={alt}
        loading={loading}
        style={{
          opacity: isLoaded ? 1 : 0, // Fades in when loaded
          transition: "opacity 0.5s ease-in-out", // Smooth fade
        }}
        onLoad={() => setIsLoaded(true)}
        onError={onError}
      />
    </LazyImageWrapper>
  );
};

export default LazyImage;
