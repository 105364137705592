// styles.js
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";

// Book Item styling
export const BookItem = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "16px",
  gap: "2px", // Spacing between image, title, and author
  width: "100%",
}));

// Thumbnail styling
export const Thumbnail = styled("img")(({ theme }) => ({
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius,
}));

// Title styling
export const BookTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem", // Adjust font size for the title
  marginTop: theme.spacing(1),
}));

// Author styling
export const BookAuthor = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem", // Adjust font size for the author
  color: theme.palette.text.secondary,
}));

// Container for the search bar and book details
export const Container = styled(Box)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
}));

// Styled TextField for search input
export const SearchInput = styled(TextField)(({ theme }) => ({
  width: "300px", // Adjust width for a narrower search bar
  marginBottom: theme.spacing(2),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
  },
}));

// Dropdown menu for search results
export const SearchResultsDropdown = styled(Menu)(({ theme }) => ({
  maxHeight: "200px",
  overflowY: "auto",
  width: "300px", // Match the width of the search bar
  marginTop: theme.spacing(0.5),
  position: "absolute",
  top: "100%",
  left: 0,
  zIndex: theme.zIndex.tooltip, // Ensure dropdown is on top
}));

// Styled MenuItem for search results
export const SearchResultItem = styled(MenuItem)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Container for book details
export const BookDetails = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: "center",
}));

// Thumbnail styling
export const BookThumbnail = styled("img")(({ theme }) => ({
  maxWidth: "100px",
  marginBottom: theme.spacing(1),
}));

// Button styling
export const AddToLibraryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// Styled Popper for dropdown
export const StyledPopper = styled((props) => <Box {...props} />)(
  ({ theme }) => ({
    position: "absolute",
    top: "100%",
    left: 0,
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
  })
);

// Styled MenuItem for each result
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: "pointer",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderBottom: "none",
  },
  whiteSpace: "nowrap", // Prevent text from wrapping
  overflow: "hidden", // Hide overflow text
  textOverflow: "ellipsis", // Show ellipsis for overflow text
}));

export const LazyImageWrapper = styled("div")(() => ({
  position: "relative",
  display: "inline-block", // Keeps image natural size without forcing container to stretch
}));

export const BlurhashPlaceholder = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "auto",
  transition: "opacity 1s ease-in-out", // Smooth fade-out effect
}));

// Image container with consistent aspect ratio
export const LazyImageContainer = styled("div")(() => ({
  width: "100%",
  maxWidth: "200px", // Optional max width for scaling
  overflow: "hidden", // Crop overflowing image
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// Image element with proportional scaling
export const LazyImageElement = styled("img")(() => ({
  objectFit: "cover", // Proportional scaling
  borderRadius: "4px",
}));
